import { useQuery } from "@tanstack/react-query";
import Input from "../shared/Input/Input";
import Select from "../shared/Select/Select";
import Checkbox from "../shared/Checkbox/Checkbox";
import CommonLayout from "./CommonLayout";
import FormItem from "../shared/FormItem/FormItem";
import { getInputs } from "../../api";

const PageAddCompanyInfo = ({
  serviceId,
  setServiceId,
  companyName,
  setCompanyName,
  serviceProvider,
  setServiceProvider,
  setTabName,
}) => {
  const { data } = useQuery({
    queryKey: ["inputs"],
    queryFn: () => getInputs(),
    refetchOnMount: false,
  });

  const saveCompanyInfo = () => {
    if (companyName === "" || serviceId === 0) return; //TODO: Add ERR Message
    setTabName("serviceLocation");
  };

  return (
    <CommonLayout index="01" handleClick={saveCompanyInfo}>
      <>
        <h2 className="text-2xl font-semibold">Izaberite delatnost</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}

        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Izaberite delatnost vase firme"
            desc="Frizerski salon, spa centar, servis automobila, notar."
          >
            <Select value={serviceId} onChange={setServiceId}>
              <option value={0} disabled>
                Izaberite delatnost
              </option>
              {data?.serviceCategories.map((service) => (
                <option key={`service-key-${service.id}`} value={service.id}>
                  {service.name}
                </option>
              ))}
            </Select>
          </FormItem>
          <FormItem label="Ime firme">
            <Input
              placeholder="Unesite ime firme"
              value={companyName}
              onChange={setCompanyName}
            />
          </FormItem>
          <FormItem
            label="Ja sam i pružalac usluga"
            desc="Ako lično pružate usluge u firmi, označite ovu opciju da bi vas mušterije mogle izabrati."
          >
            <Checkbox
              name="serviceProvider"
              value={serviceProvider}
              onChange={setServiceProvider}
            />
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddCompanyInfo;
