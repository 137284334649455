export default function GoogleSvg() {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.66 8.70094C16.66 8.09094 16.61 7.51094 16.51 6.96094H8.5V10.2409H13.08C12.9855 10.7621 12.7861 11.2587 12.4938 11.7005C12.2016 12.1422 11.8227 12.52 11.38 12.8109V14.9409H14.12C14.9576 14.1373 15.6166 13.1664 16.0542 12.0913C16.4918 11.0162 16.6982 9.86105 16.66 8.70094Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4999 17.0004C10.571 17.059 12.5858 16.3218 14.1299 14.9404L11.3799 12.8104C10.5213 13.3587 9.51826 13.6373 8.4999 13.6104C7.43985 13.5991 6.4101 13.2552 5.55602 12.6272C4.70194 11.9992 4.06668 11.1188 3.7399 10.1104H0.899902V12.3104C1.60686 13.7202 2.69213 14.9056 4.03433 15.7339C5.37653 16.5622 6.92271 17.0007 8.4999 17.0004Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.74 10.1204C3.39077 9.07208 3.39077 7.93878 3.74 6.89043V4.69043H0.900002C0.307293 5.87298 -0.000899796 7.17766 1.97323e-06 8.50043C1.97323e-06 9.87043 0.330002 11.1704 0.900002 12.3204L3.74 10.1204Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4999 3.38063C9.70798 3.36071 10.8754 3.8169 11.7499 4.65063L14.1899 2.21063C12.65 0.766482 10.6109 -0.0255206 8.4999 0.000627448C6.92376 -0.000622062 5.37831 0.436389 4.03621 1.26284C2.69412 2.08929 1.60824 3.27262 0.899902 4.68063L3.7399 6.88063C4.06668 5.87214 4.70194 4.99175 5.55602 4.36375C6.4101 3.73575 7.43985 3.39186 8.4999 3.38063Z"
        fill="#EA4335"
      />
    </svg>
  );
}
