import React from "react";
import ButtonPrimary from "../shared/Button/ButtonPrimary";
import Input from "../shared/Input/Input";
import CommonLayout from "./CommonLayout";

const AccountPass = () => {
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <h2 className="text-3xl font-semibold">Update your password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <div>
              <label>Email</label>
              <Input type="email" className="mt-1.5" />
            </div>
            <div>
              <label>Trenutna Šifra</label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div>
              <label>Nova Šifra</label>
              <Input type="password" className="mt-1.5" />
            </div>

            <div className="pt-2">
              <ButtonPrimary>Sačuvajte šifru</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
