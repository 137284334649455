// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorPage_errorPage__zkOqE {
  display: flex;
  align-items: center;
  gap: 5rem;
  width: 100%;
  height: 100vh;
  padding: 10vw;
  background: white;
}
.ErrorPage_errorPage__zkOqE h2,
.ErrorPage_errorPage__zkOqE p {
  padding: 0;
  text-align: left;
}
.ErrorPage_errorPage__zkOqE > div {
  flex-basis: 50%;
}
.ErrorPage_errorPage__zkOqE h2 {
  margin-bottom: 3rem;
}
.ErrorPage_errorPage__zkOqE p {
  margin: 1.5rem 0;
}

.ErrorPage_backHome__0Xyq8 {
  display: flex;
  margin-top: 3rem;
}
.ErrorPage_backHome__0Xyq8 > button {
  color: white;
  border-radius: 0.25rem;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ErrorPage/ErrorPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EACA,iBAAA;AACF;AACE;;EAEE,UAAA;EACA,gBAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;;AAMA;EACE,aAAA;EACA,gBAAA;AAHF;AAKE;EACE,YAAA;EACA,sBAAA;EACA,yBAAA;EACA,eAAA;AAHJ","sourcesContent":[".errorPage {\n  display: flex;\n  align-items: center;\n  gap: 5rem;\n  width: 100%;\n  height: 100vh;\n  padding: 10vw;\n  background: white;\n\n  h2,\n  p {\n    padding: 0;\n    text-align: left;\n  }\n\n  > div {\n    flex-basis: 50%;\n  }\n\n  h2 {\n    margin-bottom: 3rem;\n  }\n\n  p {\n    margin: 1.5rem 0;\n  }\n}\n\n.backHome {\n  display: flex;\n  margin-top: 3rem;\n\n  > button {\n    color: white;\n    border-radius: 0.25rem;\n    padding: 0.625rem 1.25rem;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorPage": `ErrorPage_errorPage__zkOqE`,
	"backHome": `ErrorPage_backHome__0Xyq8`
};
export default ___CSS_LOADER_EXPORT___;
