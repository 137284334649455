// import MobileFooterSticky from "./(components)/MobileFooterSticky";

const DetailLayout = ({ children }) => {
  return (
    <div className="ListingDetailPage">
      {/* <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      /> */}

      <div className="container ListingDetailPage__content">{children}</div>

      {/* STICKY FOOTER MOBILE */}
      {/* <MobileFooterSticky /> */}
    </div>
  );
};

export default DetailLayout;
