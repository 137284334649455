import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { login, userAuthentification } from "../../api";
import { useAppState } from "../../state/appState";
import FacebookSvg from "../../assets/svg/FacebookSvg";
import GoogleSvg from "../../assets/svg/GoogleSvg";
import { Helmet } from "react-helmet";
import Input from "../shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "../shared/Button/ButtonPrimary";

const LoginPage = ({ className = "" }) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ email: "", password: "" });
  const { user, setUser, setToken, setRefreshToken } = useAppState();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const { from } = location.state || { from: { pathname: "/" } };
      navigate(from, { replace: true });
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (error.email || error.password) return;
    try {
      const resp = await login({ email, password });
      const { accessToken } = resp;
      const { refreshToken } = resp;
      setToken(accessToken);
      setRefreshToken(refreshToken);

      if (accessToken) {
        const user = await userAuthentification();
        setUser(user);
        navigate("/", { replace: true });
      }
    } catch (error) {
      const msg = error?.response?.data?.errors;
      setError(msg);
    }
  };

  const resetError = (field) => {
    setError((prevError) => ({ ...prevError, [field]: "" }));
  };

  const handleEmailChange = (e) => {
    resetError("email");
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    resetError("password");
    setPassword(e.target.value);
  };

  const loginSocials = [
    {
      name: "Ulogujte se sa Facebook nalogom",
      href: "#",
      icon: FacebookSvg,
    },
    {
      name: "Ulogujte se sa Google nalogom",
      href: "#",
      icon: GoogleSvg,
    },
  ];
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className=" my-7 sm:my-10 lg:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Prijava
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <item.icon />

                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              ILI
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email adresa
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={handleEmailChange}
                value={email}
                error={{ message: error["email"] }}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Šifra
                <Link to="/forgot-pass" className="text-sm">
                  Zaboravili ste šifru?
                </Link>
              </span>
              <Input
                type="password"
                className="mt-1"
                value={password}
                onChange={handlePasswordChange}
                error={{ message: error["password"] }}
              />
            </label>
            <ButtonPrimary type="submit" onClick={handleLogin}>
              Prijava
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Novi korisnik? {` `}
            <Link to="/signup">Kreirajte novi nalog</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
