export default function FacebookSvg() {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3 0H0.7C0.514348 0 0.336301 0.0737497 0.205025 0.205025C0.0737497 0.336301 0 0.514348 0 0.7V16.3C0 16.68 0.32 17 0.7 17H9.03V10.62H6.91V7.97H9.03V5.84C9.03 3.64 10.43 2.57 12.38 2.57C13.32 2.57 14.13 2.64 14.36 2.67V4.97H13C11.94 4.97 11.69 5.47 11.69 6.21V7.97H14.34L13.81 10.62H11.7L11.74 17H16.3C16.3928 17.0013 16.4849 16.9842 16.5709 16.9496C16.657 16.915 16.7354 16.8637 16.8015 16.7985C16.8675 16.7334 16.92 16.6558 16.9558 16.5702C16.9916 16.4846 17.01 16.3928 17.01 16.3V0.7C17.01 0.514348 16.9363 0.336301 16.805 0.205025C16.6737 0.0737497 16.4957 0 16.31 0"
        fill="#3B5998"
      />
    </svg>
  );
}
