import { Route, Routes, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import {
  ProtectedRoutes,
  ErrorPage,
  HomePage,
  Navigation,
  LoginPage,
  SignUpPage,
  FooterNav,
  CompaniesList,
  AccountPage,
  VerificationPage,
  AccountPass,
  UserBusinesses,
  CompanyPreview,
  UserFavoriteList,
} from "./components";
import CreateCompany from "./components/CreateCompany/CreateCompany";

function App() {
  const navigate = useNavigate();

  return (
    <div className="App">
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onReset={() => navigate("/")}
      >
        <Navigation />
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route path="/account" element={<AccountPage />} />
            <Route path="/account-password" element={<AccountPass />} />
            <Route path="/create-company" element={<CreateCompany />} />
          </Route>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          {/* <Route path="api/email/verify/:id" element={<VerificationPage />} /> */}
          <Route path="/services-search" element={<CompaniesList />} />
          <Route path="/user-businesses" element={<UserBusinesses />} />
          <Route path="/my-savelists" element={<UserFavoriteList />} />
          <Route
            path="/user-businesses/companies/:companyId"
            element={<CompanyPreview />}
          />
          <Route
            path="/my-savelists/companies/:companyId"
            element={<CompanyPreview />}
          />
        </Routes>
        <FooterNav />
      </ErrorBoundary>
    </div>
  );
}

export default App;
