import LocationInput from "./LocationInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import ServicesInput from "./ServicesInput";
import ButtonSubmit from "./ButtonSubmit";

const ServiceSearchForm = () => {
  const renderForm = () => {
    return (
      <form className="w-full relative flex  items-center rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <LocationInput className="flex-[1.5]" />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInput className="flex-1" />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <ServicesInput className="flex-[1.5]" />
        <div className="pr-2 xl:pr-4">
          <ButtonSubmit />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default ServiceSearchForm;
