import { Link, NavLink } from "react-router-dom";
import ButtonClose from "../Button/ButtonClose";
import ButtonPrimary from "../Button/ButtonPrimary";
// import LogoSvg from "../../../assets/svg/LogoSvg";
import logoPng from "../../../assets/images/logo.png";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import SocialsList from "../SocialsList/SocialsList";
import SwitchDarkMode from "../SwitchDarkMode/SwitchDarkMode";
import LangDropdown from "../../Navigation/LangDropdown";
import { useAppState } from "../../../state/appState";
import { logout } from "../../../api";

const NavMobile = ({ onClickClose }) => {
  const { user, setUser, setToken, setRefreshToken } = useAppState();
  const data = [
    {
      name: "Usluge",
      type: "dropdown",
      children: [
        {
          name: "Izdvajamo iz ponude",
          href: "/listing-service",
        },
        {
          name: "Istorija",
          href: "/service-history",
        },
        {
          name: "Neka opcija",
          href: "/listing-experiences",
        },
        {
          name: "Neka opcija",
          href: "/listing-car",
        },
      ],
      isNew: true,
    },

    {
      name: user?.name || "Prijavi se",
      type: "dropdown",
      href: user ? null : "/login",
      isNew: true,
      children: user
        ? [
            {
              name: "Moj profil",
              href: "/account",
            },
            {
              name: "Poruke",
              href: "/messages",
            },
            {
              name: "Favoriti",
              href: "/account-savelists",
            },
            {
              name: "Zakazani termini",
              href: "/account-appointments",
            },
            {
              name: "Odjavi se",
            },
          ]
        : false,
    },
  ];
  const logoutUser = async (e) => {
    try {
      const resp = await logout();
      if (resp.message) {
        setUser(null);
        setToken(null);
        setRefreshToken(null);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _renderMenuChild = (item) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            {i.href ? (
              <NavLink
                end
                to={{
                  pathname: i.href || undefined,
                }}
                className={({ isActive }) =>
                  `flex px-4 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 ${
                    isActive ? "text-secondary" : ""
                  }`
                }
              >
                <span
                  className={`py-2.5 pr-3 ${!i.children ? "block w-full" : ""}`}
                >
                  {i.name}
                </span>
              </NavLink>
            ) : (
              <div
                className="flex px-4 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5"
                onClick={logoutUser}
              >
                {" "}
                <span
                  className={`py-2.5 pr-3 ${!i.children ? "block w-full" : ""}`}
                >
                  {i.name}
                </span>
              </div>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item, index) => {
    return (
      <Disclosure
        key={`mobile-list-item-${index}`}
        as="li"
        className="text-neutral-900 dark:text-white"
      >
        <div
          className={`flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg `}
        >
          {!item.href ? (
            <span
              className={`py-2.5 pr-3 ${!item.children ? "block w-full" : ""}`}
            >
              {item.name}
            </span>
          ) : (
            <NavLink
              end
              to={item.href}
              className="text-neutral-900 dark:text-white"
            >
              <span
                className={`py-2.5 pr-3 ${
                  !item.children ? "block w-full" : ""
                }`}
              >
                {item.name}
              </span>
            </NavLink>
          )}
          {item.children && (
            <span className="flex-1 flex" onClick={(e) => e.preventDefault()}>
              <Disclosure.Button
                as="span"
                className="py-2.5 flex items-center justify-end flex-1 "
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </div>

        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  return (
    <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <Link
          to="/"
          className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 w-48`}
        >
          <img src={logoPng} alt="hero" />
        </Link>
        <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
          <span>
            Istražite naše usluge, pronađite svoje omiljene radnje i zakazujte
            termine brzo i jednostavno.
          </span>

          <div className="flex justify-between items-center mt-4">
            <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
            <span className="block">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
            </span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {data.map(_renderItem)}
      </ul>
      <div className="flex items-center justify-between py-6 px-5">
        <ButtonPrimary>
          <Link to="/create-company">Dodaj svoj biznis</Link>
        </ButtonPrimary>
        <LangDropdown panelClassName="z-10 w-screen max-w-[280px] px-4 mb-3 -right-3 bottom-full sm:px-0" />
      </div>
    </div>
  );
};

export default NavMobile;
