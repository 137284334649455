import Badge from "../shared/Badge/Badge";
import Avatar from "../shared/Avatar/Avatar";
import StarRating from "../shared/StarRating/StarRating";
import { useAppState } from "../../state/appState";

const CompanyInfoCard = ({ companyInfoData }) => {
  const { user } = useAppState();
  return (
    <div className="listingSection__wrap !space-y-6">
      <div className="flex justify-between items-center">
        <Badge name={companyInfoData.serviceCategory.name} />
        {/* <LikeSaveBtns /> */}
        {user.id === companyInfoData.manager.id && (
          <i className="las la-edit text-3xl cursor-pointer text-brand-blue " />
        )}
      </div>

      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
        {companyInfoData.name}
      </h2>

      <div className="flex items-center space-x-4">
        <StarRating point={4.7} reviewCount={152} />
        <span>·</span>
        <span>
          <i className="las la-map-marked-alt text-brand-blue text-xl"></i>
          <span className="ml-1">
            {" "}
            {`${companyInfoData.street} br. ${companyInfoData.streetNumber}, ${companyInfoData.city.name}`}
          </span>
        </span>
      </div>

      <div className="flex items-center">
        <Avatar
          hasChecked
          sizeClass="h-10 w-10"
          radius="rounded-full"
          imgUrl={companyInfoData.manager.avatar}
        />
        <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
          Vlasnik{" "}
          <span className="text-neutral-900 dark:text-neutral-200 font-medium">
            {companyInfoData.manager.name}
          </span>
        </span>
      </div>

      <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

      <div className="flex items-center  xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
        <div className="flex items-center space-x-3 ">
          <i className=" las la-user text-2xl text-brand-lightBlue" />
          <span className="">
            <span className="sm:inline-block">
              Br. zaposlenih {companyInfoData.employees.length}
            </span>
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <i className="lab la-whmcs text-2xl text-brand-lightBlue" />
          <span className=" ">
            <span className="sm:inline-block">
              Br. usluga {companyInfoData.services.length}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfoCard;
