import { useState } from "react";
import { createPortal } from "react-dom";
import FormItem from "../shared/FormItem/FormItem";
import ButtonPrimary from "../shared/Button/ButtonPrimary";
import ButtonSecondary from "../shared/Button/ButtonSecondary";
import Input from "../shared/Input/Input";
import Select from "../shared/Select/Select";

const AddServiuceModal = ({ show, handleClose, data, handleAddService }) => {
  const ModalContent = () => {
    const [serviceSubCategoryId, setServiceSubCategoryId] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [duration, setDuration] = useState("");
    const [price, setPice] = useState("");
    const [gender, setGender] = useState("");

    const handleSave = () => {
      if (!serviceSubCategoryId || !name || !duration) return; //TO DO add validation msg
      handleAddService({
        serviceSubCategoryId,
        name,
        description,
        duration,
        price,
      });
    };

    return (
      <div className="backdrop-opacity-10 backdrop-invert bg-brand-blue/30 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 p-6 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <h3 className="text-2xl font-semibold">Dodajte uslugu</h3>

              <i
                className="las la-times-circle float-right text-3xl cursor-pointer text-brand-blue align-middle ml-6"
                onClick={handleClose}
              />
            </div>
            <div className="relative p-6 flex-auto">
              <div className="space-y-6">
                {/* ITEM */}
                <FormItem label=" Izaberite kategoruiju usluge" required>
                  <Select
                    value={serviceSubCategoryId}
                    onChange={(e) => setServiceSubCategoryId(e.target.value)}
                  >
                    <option value="">Izaberite kategoriju</option>
                    {data?.subCategories.map((serviceSubCategoryId) => (
                      <option
                        key={`service-key-${serviceSubCategoryId.id}`}
                        value={serviceSubCategoryId.id}
                      >
                        {serviceSubCategoryId.name}
                      </option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem label="Ime usluge" required>
                  <Input
                    placeholder="Unesite ime usluge"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormItem>
                <FormItem
                  label="Unesite vremensko trajanje u minutima"
                  required
                >
                  <Input
                    placeholder="75"
                    value={duration}
                    min="0"
                    type="number"
                    onChange={(e) => setDuration(e.target.value)}
                  />
                </FormItem>
                <FormItem label="Opis usluge">
                  <Input
                    placeholder="Unesite opis usluge"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormItem>

                <FormItem label="Unesite cenu">
                  <Input
                    placeholder="cena"
                    value={price}
                    min="0"
                    type="number"
                    onChange={(e) => setPice(e.target.value)}
                  />
                </FormItem>
                <FormItem label=" Izaberite pol">
                  <Select
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="">Izaberite pol</option>
                    <option value="male">Muski</option>
                    <option value="female">Zenski</option>
                  </Select>
                </FormItem>
              </div>
              <div className="flex justify-end space-x-5 mt-8">
                <ButtonSecondary onClick={handleClose}>Nazad</ButtonSecondary>
                <ButtonPrimary onClick={handleSave} type="button">
                  Sacuvaj
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {show &&
        createPortal(<ModalContent onClose={handleClose} />, document.body)}
    </>
  );
};

export default AddServiuceModal;
