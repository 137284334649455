import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getCompany } from "../../api/";
import { PHOTOS } from "../../DummyData";
import DetailLayout from "./DetailLayout";
import CompanyInfoCard from "./CompanyInfoCard";
import CompanyServices from "../CompanyServices/CompanyServices";
import EmployeeWrapper from "../CompanyEmployee/EmployeeWrapper";

const CompanyPreview = () => {
  const [isModalImageGalleryOpen, setIsModalImageGalleryOpen] = useState(false);

  const { companyId } = useParams();

  const { isPending, data, error } = useQuery({
    queryKey: ["company", companyId],
    queryFn: () => getCompany({ companyId }),
    disable: !companyId,
  });

  if (isPending) return "Loading...";
  return (
    <DetailLayout>
      <div className="nc-ListingStayDetailPage">
        {/*  HEADER */}
        <header className="rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-zoom-in "
              onClick={() => setIsModalImageGalleryOpen(true)}
            >
              <img
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                src={PHOTOS[0]}
                alt=""
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                  <img
                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                    src={item || ""}
                    alt=""
                    sizes="400px"
                  />
                </div>

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-zoom-in"
                  onClick={() => setIsModalImageGalleryOpen(true)}
                />
              </div>
            ))}
          </div>
        </header>

        {/* MAIN */}
        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
          {/* CONTENT */}
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
            <CompanyInfoCard companyInfoData={data.data} />
            <CompanyServices
              className="pb-24 lg:pb-28"
              services={data.data.services}
              manager={data.data.manager}
              categoryId={data.data.serviceCategory.id}
              companyId={data.data.id}
            />
          </div>

          {/* SIDEBAR */}
          <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
            <div className="sticky top-28">
              RENDER SIDEBAR
              <EmployeeWrapper
                employees={data.data.employees}
                managerId={data.data.manager.id}
                companyId={data.data.id}
              />
            </div>
          </div>
        </main>
      </div>
    </DetailLayout>
  );
};

export default CompanyPreview;
