import { useEffect } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { verifyEmail } from "../../api";

const VerificationPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const expires = searchParams.get("expires");
  const signature = searchParams.get("signature");

  useEffect(() => {
    (async () => {
      const response = await verifyEmail({ id, expires, signature });
      if (response) navigate("/", { replace: true });
    })();

    verifyEmail({ id, expires, signature });
  }, [expires, id, signature]);
};

export default VerificationPage;
