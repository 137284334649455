import MainNav from "./MainNav";

export default function Navigation() {
  return (
    <div
      className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg shadow-sm dark:border-b dark:border-neutral-700`}
    >
      <MainNav />
    </div>
  );
}
