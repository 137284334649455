import { useState } from "react";
import EmployeeCard from "../shared/EmployeeCard/EmployeeCard";
import { useAppState } from "../../state/appState";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getRoles, addEmployee } from "../../api";
import AddEmployeeModal from "./AddEmployeeModal";

const EmployeeWrapper = ({ employees, managerId, companyId }) => {

  const { user } = useAppState();
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);

  const queryClient = useQueryClient();

  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: () => getRoles(),
  });

  const assignCompanyEmployee = useMutation({
    mutationFn: addEmployee,
    onSuccess: (data) => {
      setShowAddEmployeeModal(false);
    },
  });

  const handleAddEmployee = (data) => {
    assignCompanyEmployee.mutate({
      data: {
        ...data,
        companyId,
      },
    });
  };

  return (
    <div
      className={`nc-SectionGridAuthorBox relative `}
      data-nc-id="SectionGridAuthorBox"
    >
      {showAddEmployeeModal && (
        <AddEmployeeModal
          show={showAddEmployeeModal}
          handleClose={() => setShowAddEmployeeModal(false)}
          roles={roles.data}
          handleAddEmployee={handleAddEmployee}
        />
      )}
      <h3 className="text-2xl font-semibold">
        Zaposleni{" "}
        {user.id === managerId && (
          <i
            onClick={() => setShowAddEmployeeModal(true)}
            className="las la-plus-circle text-3xl cursor-pointer text-brand-blue align-middle ml-6"
          />
        )}
      </h3>
      <div className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 mt-5`}>
        {employees.map((employee) => (
          <EmployeeCard key={employee.id} employee={employee} />
        ))}
      </div>
    </div>
  );
};

export default EmployeeWrapper;
