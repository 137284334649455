const routes = {
  login: `/auth/login`,
  logout: `/auth/logout`,
  register: `/auth/register`,
  refreshToken: `/auth/refresh`,
  userAuthentification: `/auth/me`,
  updateUserInfo: `/profile`,
  verifyEmail: `/email/verify`,
  home: "/home",
  createCompany: "/companies/create",
  company: "/companies",
  companyServices: "/companies/services",
  addFavorites: "/set_as_favorite",
  removeFavorites: "/unset_as_favorite",
  userFavorites: "/get_favorite_companies",
  roles: "/companies/roles",
  addEmployee: "/assign-user",
};

export default routes;
