export const DEMO_STAYS = [
  {
    id: "11",
    authorId: 10,
    date: "May 20, 2021",
    href: "/listing-stay-detail",
    listingCategoryId: 17,
    title: "GM Beauty studio",
    featuredImage:
      "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    galleryImgs: [
      "https://images.pexels.com/photos/1268871/pexels-photo-1268871.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/1179156/pexels-photo-1179156.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/2506988/pexels-photo-2506988.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/2373201/pexels-photo-2373201.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    ],
    commentCount: 70,
    viewCount: 602,
    like: false,
    address: "Đorđa Ognjanovića 16",
    reviewStart: 4.8,
    reviewCount: 28,
    price: "$26",
    maxGuests: 6,
    bedrooms: 10,
    bathrooms: 3,
    saleOff: "-20% today",
    isAds: true,
    map: {
      lat: 44.800834114881106,
      lng: 20.4966253495045,
    },
    author: {
      id: 10,
      firstName: "Mimi",
      lastName: "Fones",
      displayName: "Fones Mimi",
      email: "mfones9@canalblog.com",
      gender: "Agender",
      avatar: "/static/media/Image-10.93048ca791076288cf69.png",
      count: 111,
      href: "/author",
      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
      jobName: "Author Job",
      bgImage:
        "https://images.pexels.com/photos/5966631/pexels-photo-5966631.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
    listingCategory: {
      id: 17,
      name: "Frizersko kozmetički salon",
      href: "archive-stay/the-demo-archive-slug",
      thumbnail: "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
      count: 2855,
      taxonomy: "category",
      listingType: "stay",
    },
  },
  {
    id: "2",
    authorId: 1,
    date: "May 20, 2021",
    href: "/listing-stay-detail",
    listingCategoryId: 9,
    title: "Bell by Greene King Inns ",
    featuredImage:
      "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    galleryImgs: [
      "https://images.pexels.com/photos/6434634/pexels-photo-6434634.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/3201735/pexels-photo-3201735.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/4577673/pexels-photo-4577673.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/6474535/pexels-photo-6474535.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    ],
    commentCount: 16,
    viewCount: 196,
    like: false,
    address: "32923 Judy Hill",
    reviewStart: 4.4,
    reviewCount: 198,
    price: "$250",
    maxGuests: 10,
    bedrooms: 6,
    bathrooms: 7,
    saleOff: "-10% today",
    isAds: null,
    map: {
      lat: 55.1972153,
      lng: 61.4407266,
    },
    author: {
      id: 1,
      firstName: "Alric",
      lastName: "Truelock",
      displayName: "Truelock Alric",
      email: "atruelock0@skype.com",
      gender: "Bigender",
      avatar: "/static/media/Image-1.90baa8cc883db8970fda.png",
      bgImage:
        "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
      count: 40,
      href: "/author",
      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
      jobName: "Author Job",
    },
    listingCategory: {
      id: 9,
      name: "Entire cabin",
      href: "archive-stay/the-demo-archive-slug",
      thumbnail: "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
      count: 1465,
      taxonomy: "category",
      listingType: "stay",
    },
  },
  {
    id: "stayListing_2_",
    authorId: 6,
    date: "May 20, 2021",
    href: "/listing-stay-detail",
    listingCategoryId: 11,
    title: "Half Moon, Sherborne by Marston's Inns ",
    featuredImage:
      "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    galleryImgs: [
      "https://images.pexels.com/photos/4186560/pexels-photo-4186560.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/2404422/pexels-photo-2404422.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/756078/pexels-photo-756078.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    ],
    commentCount: 47,
    viewCount: 843,
    like: true,
    address: "6731 Killdeer Park",
    reviewStart: 3.6,
    reviewCount: 16,
    price: "$278",
    maxGuests: 9,
    bedrooms: 9,
    bathrooms: 8,
    saleOff: null,
    isAds: null,
    map: {
      lat: 55.247483,
      lng: 61.5229791,
    },
    author: {
      id: 6,
      firstName: "Donna",
      lastName: "Friar",
      displayName: "Friar Donna",
      email: "dfriar5@telegraph.co.uk",
      gender: "Agender",
      avatar: "/static/media/Image-6.f9fbe7060b79c99c7a60.png",
      count: 31,
      href: "/author",
      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
      jobName: "Author Job",
      bgImage:
        "https://images.pexels.com/photos/5333590/pexels-photo-5333590.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
    listingCategory: {
      id: 11,
      name: "Entire cabin",
      href: "archive-stay/the-demo-archive-slug",
      thumbnail: "http://dummyimage.com/300x300.png/dddddd/000000",
      count: 2775,
      taxonomy: "category",
      listingType: "stay",
    },
  },
  {
    id: "stayListing_3_",
    authorId: 8,
    date: "May 20, 2021",
    href: "/listing-stay-detail",
    listingCategoryId: 6,
    title: "White Horse Hotel by Greene King Inns ",
    featuredImage:
      "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    galleryImgs: [
      "https://images.pexels.com/photos/2290738/pexels-photo-2290738.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/751268/pexels-photo-751268.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/3201922/pexels-photo-3201922.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/2404422/pexels-photo-2404422.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    ],
    commentCount: 6,
    viewCount: 91,
    like: false,
    address: "35 Sherman Park",
    reviewStart: 4.8,
    reviewCount: 34,
    price: "$40",
    maxGuests: 6,
    bedrooms: 7,
    bathrooms: 5,
    saleOff: null,
    isAds: null,
    map: {
      lat: 55.2342034,
      lng: 61.5409607,
    },
    author: {
      id: 8,
      firstName: "Claudetta",
      lastName: "Sleite",
      displayName: "Sleite Claudetta",
      email: "csleite7@godaddy.com",
      gender: "Genderqueer",
      avatar: "/static/media/Image-8.5ae85a64aab1965e33a5.png",
      count: 35,
      href: "/author",
      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
      jobName: "Author Job",
      bgImage:
        "https://images.pexels.com/photos/5083491/pexels-photo-5083491.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
    listingCategory: {
      id: 6,
      name: "Entire cabin",
      href: "archive-stay/the-demo-archive-slug",
      thumbnail: "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
      count: 1614,
      taxonomy: "category",
      listingType: "stay",
    },
  },
  {
    id: "stayListing_4_",
    authorId: 2,
    date: "May 20, 2021",
    href: "/listing-stay-detail",
    listingCategoryId: 11,
    title: "Ship and Castle Hotel ",
    featuredImage:
      "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    galleryImgs: [
      "https://images.pexels.com/photos/261327/pexels-photo-261327.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/2079452/pexels-photo-2079452.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/261410/pexels-photo-261410.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/2983472/pexels-photo-2983472.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    ],
    commentCount: 91,
    viewCount: 574,
    like: false,
    address: "3 Crest Line Park",
    reviewStart: 3.4,
    reviewCount: 340,
    price: "$147",
    maxGuests: 8,
    bedrooms: 3,
    bathrooms: 2,
    saleOff: null,
    isAds: null,
    map: {
      lat: 55.2288039,
      lng: 61.6050232,
    },
    author: {
      id: 2,
      firstName: "Chariot",
      lastName: "Birrell",
      displayName: "Birrell Chariot",
      email: "cbirrell1@google.com.hk",
      gender: "Genderfluid",
      avatar: "/static/media/Image-2.405c62ff9ad88c47e28c.png",
      count: 113,
      href: "/author",
      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
      jobName: "Author Job",
      bgImage:
        "https://images.pexels.com/photos/5799379/pexels-photo-5799379.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
    listingCategory: {
      id: 11,
      name: "Entire cabin",
      href: "archive-stay/the-demo-archive-slug",
      thumbnail: "http://dummyimage.com/300x300.png/dddddd/000000",
      count: 2775,
      taxonomy: "category",
      listingType: "stay",
    },
  },
  {
    id: "stayListing_5_",
    authorId: 4,
    date: "May 20, 2021",
    href: "/listing-stay-detail",
    listingCategoryId: 7,
    title: "The Windmill Family & Commercial Hotel ",
    featuredImage:
      "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    galleryImgs: [
      "https://images.pexels.com/photos/2373201/pexels-photo-2373201.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/2736187/pexels-photo-2736187.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/6900098/pexels-photo-6900098.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/1371313/pexels-photo-1371313.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    ],
    commentCount: 21,
    viewCount: 210,
    like: true,
    address: "55974 Waxwing Junction",
    reviewStart: 3.8,
    reviewCount: 508,
    price: "$90",
    maxGuests: 8,
    bedrooms: 7,
    bathrooms: 7,
    saleOff: null,
    isAds: null,
    map: {
      lat: 55.1952216,
      lng: 61.6115793,
    },
    author: {
      id: 4,
      firstName: "Agnes",
      lastName: "Falconar",
      displayName: "Falconar Agnes",
      email: "afalconar3@google.ru",
      gender: "Non-binary",
      avatar: "/static/media/Image-4.36899b28c72dc4bc41a9.png",
      count: 36,
      href: "/author",
      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
      jobName: "Author Job",
      bgImage:
        "https://images.pexels.com/photos/2394446/pexels-photo-2394446.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
    listingCategory: {
      id: 7,
      name: "Entire cabin",
      href: "archive-stay/the-demo-archive-slug",
      thumbnail: "http://dummyimage.com/300x300.png/cc0000/ffffff",
      count: 1577,
      taxonomy: "category",
      listingType: "stay",
    },
  },
  {
    id: "stayListing_6_",
    authorId: 4,
    date: "May 20, 2021",
    href: "/listing-stay-detail",
    listingCategoryId: 20,
    title: "Unicorn, Gunthorpe by Marston's Inns ",
    featuredImage:
      "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    galleryImgs: [
      "https://images.pexels.com/photos/3068519/pexels-photo-3068519.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/1638341/pexels-photo-1638341.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/8506437/pexels-photo-8506437.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/3935311/pexels-photo-3935311.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    ],
    commentCount: 10,
    viewCount: 311,
    like: false,
    address: "79361 Chinook Place",
    reviewStart: 3,
    reviewCount: 481,
    price: "$282",
    maxGuests: 9,
    bedrooms: 2,
    bathrooms: 5,
    saleOff: "-10% today",
    isAds: null,
    map: {
      lat: 55.2211607,
      lng: 61.5065318,
    },
    author: {
      id: 4,
      firstName: "Agnes",
      lastName: "Falconar",
      displayName: "Falconar Agnes",
      email: "afalconar3@google.ru",
      gender: "Non-binary",
      avatar: "/static/media/Image-4.36899b28c72dc4bc41a9.png",
      count: 36,
      href: "/author",
      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
      jobName: "Author Job",
      bgImage:
        "https://images.pexels.com/photos/2394446/pexels-photo-2394446.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
    listingCategory: {
      id: 20,
      name: "Entire cabin",
      href: "archive-stay/the-demo-archive-slug",
      thumbnail: "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
      count: 2279,
      taxonomy: "category",
      listingType: "stay",
    },
  },
  {
    id: "stayListing_7_",
    authorId: 8,
    date: "May 20, 2021",
    href: "/listing-stay-detail",
    listingCategoryId: 10,
    title: "Holiday Inn Express Ramsgate Minster, an IHG Hotel ",
    featuredImage:
      "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    galleryImgs: [
      "https://images.pexels.com/photos/2343466/pexels-photo-2343466.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
      "https://images.pexels.com/photos/276746/pexels-photo-276746.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/1571469/pexels-photo-1571469.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/3946663/pexels-photo-3946663.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    ],
    commentCount: 40,
    viewCount: 905,
    like: true,
    address: "6 Chive Avenue",
    reviewStart: 3.9,
    reviewCount: 188,
    price: "$79",
    maxGuests: 6,
    bedrooms: 7,
    bathrooms: 4,
    saleOff: null,
    isAds: null,
    map: {
      lat: 55.2126851,
      lng: 61.4912261,
    },
    author: {
      id: 8,
      firstName: "Claudetta",
      lastName: "Sleite",
      displayName: "Sleite Claudetta",
      email: "csleite7@godaddy.com",
      gender: "Genderqueer",
      avatar: "/static/media/Image-8.5ae85a64aab1965e33a5.png",
      count: 35,
      href: "/author",
      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
      jobName: "Author Job",
      bgImage:
        "https://images.pexels.com/photos/5083491/pexels-photo-5083491.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    },
    listingCategory: {
      id: 10,
      name: "Entire cabin",
      href: "archive-stay/the-demo-archive-slug",
      thumbnail: "http://dummyimage.com/300x300.png/dddddd/000000",
      count: 1945,
      taxonomy: "category",
      listingType: "stay",
    },
  },
];

export const PHOTOS = [
  "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1365425/pexels-photo-1365425.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/914128/pexels-photo-914128.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/840667/pexels-photo-840667.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/732632/pexels-photo-732632.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/450062/pexels-photo-450062.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/917510/pexels-photo-917510.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/1194233/pexels-photo-1194233.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/236973/pexels-photo-236973.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/1392099/pexels-photo-1392099.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/547116/pexels-photo-547116.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/1002272/pexels-photo-1002272.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/917511/pexels-photo-917511.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/771079/pexels-photo-771079.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/13461077/pexels-photo-13461077.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/9074921/pexels-photo-9074921.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/9336042/pexels-photo-9336042.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/5418318/pexels-photo-5418318.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/4815278/pexels-photo-4815278.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/1365425/pexels-photo-1365425.jpeg?auto=compress&cs=tinysrgb&w=1600",
];
