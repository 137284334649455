import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createCompany } from "../../api";
import { useNavigate } from "react-router-dom";
import PageAddCompanyInfo from "./PageAddCompanyInfo";
import PageAddLocation from "./PageAddLocation";

const CreateCompany = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [tabName, setTabName] = useState("companyInfo");
  const [serviceId, setServiceId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [serviceProvider, setServiceProvider] = useState(false);
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [location, setLocation] = useState({
    lat: 44.8125449,
    lng: 20.46123,
  });

  const companyCreation = useMutation({
    mutationFn: createCompany,
    onSuccess: (data) => {
      // Invalidate and refetch
      console.log("data", data);
      queryClient.invalidateQueries({ queryKey: ["myBusinesses"] });
      navigate("/user-businesses");
    },
  });

  const handleSubmit = () => {
    companyCreation.mutate({
      serviceCategoryId: serviceId,
      name: companyName,
      serviceProvider: serviceProvider,
      city: city,
      street: street,
      streetNumber: streetNumber,
      location: location,
    });
  };

  return (
    <>
      {tabName === "companyInfo" && (
        <PageAddCompanyInfo
          setTabName={setTabName}
          serviceId={serviceId}
          setServiceId={(e) => setServiceId(e.target.value)}
          companyName={companyName}
          setCompanyName={(e) => setCompanyName(e.target.value)}
          serviceProvider={serviceProvider}
          setServiceProvider={(e) => {
            setServiceProvider(e.target.checked);
          }}
        />
      )}
      {tabName === "serviceLocation" && (
        <PageAddLocation
          setTabName={setTabName}
          city={city}
          setCity={setCity}
          street={street}
          setStreet={setStreet}
          streetNumber={streetNumber}
          setStreetNumber={setStreetNumber}
          location={location}
          setLocation={setLocation}
          saveCompany={handleSubmit}
        />
      )}
    </>
  );
};

export default CreateCompany;
