import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./ErrorPage.module.scss";

const ErrorPage = ({ error, resetErrorBoundary }) => {
  const firstMountRef = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const errorStatus = error.response?.status;

  useEffect(() => {
    if (errorStatus === 403) {
      navigate("/", { replace: true });
    }

    if (firstMountRef.current) resetErrorBoundary();
    else firstMountRef.current = true;
  }, [errorStatus, location, navigate, resetErrorBoundary]);

  const handleReset = () => {
    resetErrorBoundary();
  };

  const notFoundMessage = (
    <>
      <h1>
        404 <br />
        Not Found
      </h1>
    </>
  );

  const genericMessage = (
    <>
      <h2>Something went wrong...</h2>
      <p>
        We understand that it can be frustrating when things don't go as
        expected and we sincerely apologize for any inconvenience this may have
        caused. Our team is working hard to get everything back on track as soon
        as possible.
      </p>
      <p>
        In the meantime, feel free to navigate to the homepage. If the issue
        persists, please, don't hesitate to contact us.
      </p>
    </>
  );

  if (errorStatus === 403) return null;

  return (
    <section className={style.errorPage}>
      <div className={style.errorText}>
        {errorStatus === 404 ? notFoundMessage : genericMessage}
        <div className={style.backHome}>
          <button type="button" onClick={handleReset}>
            Back Home
          </button>
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
