import ButtonPrimary from "../shared/Button/ButtonPrimary";
import ButtonSecondary from "../shared/Button/ButtonSecondary";

const CommonLayout = ({
  index = "01",
  children,
  nextBtnText,
  handleBack = false,
  handleClick = () => {},
  type = "button",
}) => {
  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">{index}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 2
          </span>
        </div>

        {/* --------------------- */}
        <div className="listingSection__wrap ">{children}</div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          {handleBack && (
            <ButtonSecondary onClick={handleBack}>Nazad</ButtonSecondary>
          )}

          <ButtonPrimary onClick={handleClick} type={type}>
            {nextBtnText || "Nastavite dalje"}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
