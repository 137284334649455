import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppState } from "../../state/appState";
import ServiceCard from "../shared/ServiceCard/ServiceCard";
import AddServiuceModal from "./AddServiceModal";
import { getInputs, createCompanyService } from "../../api";

const CompanyServices = ({
  className,
  services,
  manager,
  categoryId,
  companyId,
}) => {
  const { user } = useAppState();
  const [showAddServiceModal, setShowAddServiceModal] = useState(false);

  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ["inputs"],
    queryFn: () => getInputs(),
    refetchOnMount: false,
  });

  const addCompanyService = useMutation({
    mutationFn: createCompanyService,
    onSuccess: (data) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["company"] });
      setShowAddServiceModal(false);
    },
  });

  const handleAddService = (data) => {
    addCompanyService.mutate({
      data: {
        ...data,
        companyId,
      },
    });
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {showAddServiceModal && (
        <AddServiuceModal
          show={showAddServiceModal}
          handleClose={() => setShowAddServiceModal(false)}
          data={
            data.serviceCategories.filter(
              (category) => category.id === categoryId
            )[0]
          }
          handleAddService={handleAddService}
        />
      )}
      <h3 className="text-2xl font-semibold">
        Usluge kompanije{" "}
        {user.id === manager.id && (
          <i
            onClick={() => setShowAddServiceModal(true)}
            className="las la-plus-circle text-3xl cursor-pointer text-brand-green align-middle ml-6"
          />
        )}
      </h3>

      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6 mt-6 rounded-3xl">
        {services.map((item, index) => (
          <ServiceCard defaultOpen={!index} key={index} data={item} />
        ))}
      </div>
    </div>
  );
};

export default CompanyServices;
