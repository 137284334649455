import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const useAppState = create(
  devtools(
    persist(
      (set) => ({
        user: null,
        setUser: (user) => set({ user }),
        token: null,
        setToken: (token) => set({ token }),
        refreshToken: null,
        setRefreshToken: (refreshToken) => set({ refreshToken }),
        inputs: null,
        setInputs: (inputs) => set({ inputs }),
      }),
      {
        partialize: (state) => ({
          token: state.token,
          user: state.user,
          refreshToken: state.refreshToken,
        }),
        name: "eventsAppStorage",
        version: 1,
      }
    )
  )
);
