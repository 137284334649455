import ReactDOM from "react-dom/client";
import AppWrappers from "./AppWrappers";
import App from "./App";
import "./index.scss";
import "./styles/index.scss";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

ReactDOM.createRoot(document.getElementById("root")).render(
  <AppWrappers>
    <App />
  </AppWrappers>
);
