import { useState } from "react";
import AnyReactComponent from "../AnyReactComponent/AnyReactComponent";
import StoreCard from "../StoreCard/StoreCard";
import GoogleMapReact from "google-map-react";

// import ButtonClose from "shared/ButtonClose/ButtonClose";
// import Checkbox from "shared/Checkbox/Checkbox";
// import Pagination from "shared/Pagination/Pagination";
// import TabFilters from "./TabFilters";
import { DEMO_STAYS } from "../../DummyData";

const ListWithMap = () => {
  const [currentHoverID, setCurrentHoverID] = useState(0);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);

  return (
    <div>
      <div className="relative flex min-h-screen">
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <div className="lg:mb-16 mt-8 sm:mb-13 mb-4">
            <h2 className="sm:text-4xl text-2xl font-semibold">
              Rezultati za Beograd
            </h2>

            <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
              150 pružalaca usluga
              <span className="mx-2">·</span>
              Aug 12 - 18
            </span>
          </div>
          <div className="mb-8 lg:mb-11">{/* <TabFilters /> */}</div>
          <div
            className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 `}
          >
            {DEMO_STAYS.map((item) => (
              <StoreCard data={item} key={item.id} />
            ))}
          </div>
          <div className="flex mt-16 justify-center items-center">
            {/* <Pagination /> */}
          </div>
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {/* {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )} */}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white dark:bg-neutral-800 shadow-xl z-10 rounded-2xl min-w-max">
              {/* <Checkbox
                className="text-xs xl:text-sm"
                name="xx"
                label="Search as I move the map"
              /> */}
            </div>

            <GoogleMapReact
              defaultZoom={12}
              defaultCenter={DEMO_STAYS[0].map}
              bootstrapURLKeys={{
                key: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
              }}
              yesIWantToUseGoogleMapApiInternals
            >
              {DEMO_STAYS.map((item) => (
                <AnyReactComponent
                  isSelected={currentHoverID === item.id}
                  key={item.id}
                  lat={item.map.lat}
                  lng={item.map.lng}
                  listing={item}
                />
              ))}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListWithMap;
