import Pusher from "pusher-js";
import { useAppState } from "../../state/appState";

export const createPusherInstance = () => {
  // const { token } = JSON.parse(localStorage.getItem('collabAppStorage')).state;
  const state = useAppState.getState();
  const pusher = new Pusher(`a0a8c2bf3b8d0c95a0ca`, {
    cluster: "eu",
    encrypted: true,
    // authEndpoint: `${import.meta.env.VITE_API_URL}/broadcasting/auth`,
    // auth: {
    //     headers: {
    //         Authorization: `Bearer ${token || state.token}`,
    //     },
    // },
  });
  return pusher;
};
