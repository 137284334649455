import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FacebookSvg from "../../assets/svg/FacebookSvg";
import GoogleSvg from "../../assets/svg/GoogleSvg";
import { Helmet } from "react-helmet";
import Input from "../shared/Input/Input";
import Select from "../shared/Select/Select";
import { useAppState } from "../../state/appState";
import { Link } from "react-router-dom";
import { register } from "../../api";
import ButtonPrimary from "../shared/Button/ButtonPrimary";

const SignUpPage = ({ className = "" }) => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [error, setError] = useState({
    password: "",
    passwordConfirmation: "",
    name: "",
    email: "",
    phone: "",
  });
  const { setUser } = useAppState();
  const navigate = useNavigate();

  const createAccount = async (e) => {
    e.preventDefault();
    if (passwordConfirmation !== password) {
      setError({
        passwordConfirmation: "Šifre se ne poklapaju, pokušajte ponovo.",
      });
      return;
    }
    if (name === "") {
      setError({
        name: "Ime i prezime je obavezno polje",
      });
      return;
    }
    if (email === "") {
      setError({
        email: "Email je obavezno polje",
      });
      return;
    }
    if (password === "") {
      setError({
        password: "Šifra je obavezno polje",
      });
      return;
    }
    if (phone === "") {
      setError({
        password: "Telefon je obavezno polje",
      });
      return;
    }
    setError({});
    try {
      const resp = await register({
        name,
        email,
        password,
        passwordConfirmation,
        phone,
        city,
        gender,
      });
      if (resp) {
        setUser(resp.data.user);
        navigate("/");
      }
    } catch (error) {
      const msg = error?.response.data.message; //TO DO: Implement error handling
      setError(msg);
    }
  };

  const loginSocials = [
    {
      name: "Continue with Facebook",
      href: "#",
      icon: FacebookSvg,
    },
    {
      name: "Continue with Google",
      href: "#",
      icon: GoogleSvg,
    },
  ];
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Registracija</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Registracija
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <item.icon />

                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              ILI
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Ime i prezime *
              </span>
              <Input
                type="text"
                placeholder="Milos Peric"
                className="mt-1"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={{ message: error["name"] }}
                required
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email adresa *
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={{ message: error["email"] }}
                required
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Šifra *
              </span>
              <Input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1"
                value={password}
                error={{ message: error["password"] }}
                required
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Ponovite šifru *
              </span>
              <Input
                type="password"
                className="mt-1"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                error={{ message: error["passwordConfirmation"] }}
                required
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Telefon *
              </span>
              <Input
                type="number"
                className="mt-1"
                placeholder="0601234567"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                error={{ message: error["phone"] }}
                required
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Grad
              </span>
              <Input
                type="text"
                className="mt-1"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                error={{ message: error["city"] }}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Pol
              </span>
              <Select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="" disabled>
                  Izaberite pol
                </option>
                <option value="male">Muški</option>
                <option value="femail">Ženski</option>
              </Select>
            </label>

            <ButtonPrimary type="submit" onClick={createAccount}>
              Registruj se
            </ButtonPrimary>
          </form>
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Već imate nalog? {` `}
            <Link to="/login">Ulogujte se</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
