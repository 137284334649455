import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useAppState } from "../state/appState";

const ProtectedRoutes = () => {
  const { user } = useAppState();
  let location = useLocation();

  return user ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;
