import axios from "axios";
import Qs from "qs";
import humps from "humps";
import routes from "./routes";
import { useAppState } from "../state/appState";

const INSTANCE = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  withCredentials: false,
  paramsSerializer: (params) =>
    Qs.stringify(humps.decamelizeKeys(params), {
      skipNulls: true,
      arrayFormat: "indices",
    }),
});

INSTANCE.interceptors.request.use(
  (req) => {
    const eventsAppStorage = JSON.parse(
      localStorage.getItem("eventsAppStorage")
    );
    const { token } = eventsAppStorage?.state;
    if (["post", "get", "put"].includes(req.method)) {
      if (!req.url.includes("set_avatar"))
        req.data = humps.decamelizeKeys(req.data);
    }
    if (token) {
      req.headers = {
        ...req.headers,
        authorization: `Bearer ${token}`,
      };
    }
    return req;
  },
  (err) => Promise.reject(err)
);

INSTANCE.interceptors.response.use(
  (res) => {
    if (res.config.responseType !== "blob")
      res.data = humps.camelizeKeys(res.data);

    return res;
  },
  async (err) => {
    const originalRequest = err.config;
    if (err.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const setState = useAppState.setState;
      const eventsAppStorage = JSON.parse(
        localStorage.getItem("eventsAppStorage")
      );
      const { refreshToken } = eventsAppStorage?.state;
      axios
        .post(
          `${process.env.REACT_APP_API_URL}${routes.refreshToken}`,
          {},
          {
            headers: {
              authorization: `Bearer ${refreshToken}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            const accessToken = res.data.access_token;
            setState({ token: accessToken });
            originalRequest.headers = {
              ...originalRequest.headers,
              authorization: `Bearer ${accessToken}`,
            };
            return INSTANCE(originalRequest);
          }
        })
        .catch((err) => {
          setState({ token: null });
          setState({ refreshToken: null });
          setState({ user: null });
          return window.location.replace("/login");
        });
    }

    return Promise.reject(err);
  }
);

const request = (options) => INSTANCE(options).then(({ data }) => data);

export const get = (url, options) =>
  request({ url, method: "get", ...options });

export const deleteReq = (url, options) =>
  request({ url, method: "delete", ...options });

export const post = (url, data, options) =>
  request({ url, method: "post", data, ...options });

export const put = (url, data, options) =>
  request({ url, method: "put", data, ...options });

export default INSTANCE;
