import ServiceSearchForm from "./ServiceSearchForm/ServiceSearchForm";

const HeroSearchForm = ({ className = "" }) => {
  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
    >
      <ServiceSearchForm />
    </div>
  );
};

export default HeroSearchForm;
