import {
  HeartIcon,
  HomeModernIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuBar from "../shared/MenuBar/MenuBar";
import isInViewport from "../utils/isInViewport";
import useWindowSize from "../../hooks/useWindowResize";
import { useAppState } from "../../state/appState";
import Avatar from "../shared/Avatar/Avatar";

let WIN_PREV_POSITION = window.scrollY;

const NAV = [
  {
    name: "Početna",
    link: "/",
    icon: HomeModernIcon,
  },
  {
    name: "Favoriti",
    link: "/account-savelists",
    icon: HeartIcon,
  },
  // {
  //   name: "Log in",
  //   link: "/account",
  //   icon: UserCircleIcon,
  // },
  // {
  //   name: "Menu",
  //   icon: MenuBar,
  // },
];

const FooterNav = () => {
  const { user } = useAppState();
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  const containerRef = useRef(null);
  //

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    // SHOW _ HIDE MAIN MENU
    if (currentScrollPos > WIN_PREV_POSITION) {
      if (
        isInViewport(containerRef.current) &&
        currentScrollPos - WIN_PREV_POSITION < 80
      ) {
        return;
      }

      containerRef.current.classList.add("FooterNav--hide");
    } else {
      if (
        !isInViewport(containerRef.current) &&
        WIN_PREV_POSITION - currentScrollPos < 80
      ) {
        return;
      }
      containerRef.current.classList.remove("FooterNav--hide");
    }

    WIN_PREV_POSITION = currentScrollPos;
  };

  return (
    <>
      {WIN_WIDTH < 768 && (
        <div
          ref={containerRef}
          className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700 
     transition-transform duration-300 ease-in-out"
        >
          <div className="w-full max-w-lg flex justify-around mx-auto text-sm text-center ">
            {/* MENU */}
            {NAV.map((item, index) => {
              const active = location.pathname === item.link;
              return (
                <Link
                  key={index}
                  to={item.link}
                  className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                    active ? "text-neutral-900 dark:text-neutral-100" : ""
                  }`}
                >
                  <item.icon
                    className={`w-6 h-6 ${active ? "text-red-600" : ""}`}
                  />
                  <span className="text-[11px] leading-none mt-1">
                    {item.name}
                  </span>
                </Link>
              );
            })}
            {!user ? (
              <Link
                to="/login"
                className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 `}
              >
                <UserCircleIcon className={`w-6 h-6`} />
                <span className="text-[11px] leading-none mt-1">
                  Prijavi se
                </span>
              </Link>
            ) : (
              <Link
                className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 `}
                to="/account"
              >
                <Avatar
                  sizeClass="w-8 h-8 sm:w-9 sm:h-9"
                  userName={user.name}
                  imgUrl={user.avatar}
                />
                <span className="text-[11px] leading-none mt-1">
                  {user.name}
                </span>
              </Link>
            )}
            <div
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 `}
            >
              <MenuBar iconClassName="w-6 h-6" className={``} />
              <span className="text-[11px] leading-none mt-1">Meni</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FooterNav;
