import routes from "./routes";

import { deleteReq, get, post, put } from "./config";

export const login = ({ email, password }) =>
  post(`${routes.login}`, { email, password });

export const register = ({
  name,
  email,
  password,
  passwordConfirmation,
  phone,
  city,
  gender,
}) =>
  post(routes.register, {
    name,
    email,
    password,
    phone,
    city,
    gender,
    passwordConfirmation,
  });

export const verifyEmail = ({ id, expires, signature }) =>
  post(`${routes.verifyEmail}/${id}`);

export const logout = () => post(routes.logout);

export const userAuthentification = () => post(routes.userAuthentification);

export const updateUserInfo = ({ data }) =>
  put(`${routes.updateUserInfo}/${data.id}/update_info`, data);

export const getInputs = () => get(routes.home);

export const updateUserProfileImage = ({ userId, formData }) =>
  post(`${routes.updateUserInfo}/${userId}/set_avatar`, formData);

// export const getCompanyServices = ({companyId}) => get(`${routes.companyServices}/${companyId}`);

export const createCompany = ({
  serviceCategoryId,
  name,
  serviceProvider,
  city,
  street,
  streetNumber,
  location,
}) =>
  post(routes.createCompany, {
    serviceCategoryId,
    name,
    serviceProvider,
    city,
    street,
    streetNumber,
    location,
  });
// export const createCompany = () => {
//   return new Promise((resolve, reject) => {
//     // Simulacija asinhronog poziva (npr. poziv ka bazi podataka ili eksternom API-ju)
//     setTimeout(() => {
//       const data = [
//         { id: 1, name: "John Doe", age: 30 },
//         { id: 2, name: "Jane Smith", age: 25 },
//         { id: 3, name: "Michael Johnson", age: 35 },
//       ];
//       resolve(data);
//     }, 1000); // Simulacija kašnjenja od 1 sekunde
//   });
// };

export const getCompany = ({ companyId }) =>
  get(`${routes.company}/${companyId}/get`);

export const getUserCompanies = ({ userId }) =>
  get(`profile/${userId}${routes.company}`);

export const createCompanyService = ({ data }) =>
  post(routes.companyServices, { ...data });

export const setAsFavorite = ({ companyId, userId }) =>
  post(`profile/${userId}/${companyId}${routes.addFavorites}`);

export const unsetFavorite = ({ companyId, userId }) =>
  post(`profile/${userId}/${companyId}${routes.removeFavorites}`);

export const getFavorites = ({ userId }) =>
  get(`profile/${userId}${routes.userFavorites}`);

export const getRoles = () => get(routes.roles);

export const addEmployee = ({ data }) =>
  post(`${routes.company}/${data.companyId}${routes.addEmployee}`, {
    ...data,
  });
