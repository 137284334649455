import { useState } from "react";
import { createPortal } from "react-dom";
import FormItem from "../shared/FormItem/FormItem";
import ButtonPrimary from "../shared/Button/ButtonPrimary";
import ButtonSecondary from "../shared/Button/ButtonSecondary";
import Select from "../shared/Select/Select";
import Input from "../shared/Input/Input";

const AddEmployeeModal = ({ show, handleClose, roles, handleAddEmployee }) => {
  const ModalContent = () => {
    const [email, setEmail] = useState("");
    const [roleId, setRoleId] = useState("");

    const handleSave = () => {
      if (!email || !roleId) return; //TO DO add validation msg
      handleAddEmployee({
        email,
        roleId,
      });
    };

    return (
      <div className=" backdrop-opacity-10 backdrop-invert bg-brand-blue/30 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 p-6 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <h3 className="text-2xl font-semibold">Dodajte zaposlenog</h3>
              <i
                className="las la-times-circle float-right text-3xl cursor-pointer text-brand-blue align-middle ml-6"
                onClick={handleClose}
              />
            </div>
            <div className="relative p-6 flex-auto">
              <div className="space-y-6">
                {/* ITEM */}
                <FormItem label="Unesite email zaposlenog" required>
                  <Input value={email} onChange={(e)=>setEmail(e.target.value)} type="email"/>
                </FormItem>
                <FormItem label="Rola" required>
                  <Select
                    value={roleId}
                    onChange={(e) => setRoleId(e.target.value)}
                  >
                    <option value="" disabled>
                      Izaberite rolu
                    </option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </Select>
                </FormItem>
              </div>
              <div className="flex justify-end space-x-5 mt-8">
                <ButtonSecondary onClick={handleClose}>Nazad</ButtonSecondary>
                <ButtonPrimary onClick={handleSave} type="button">
                  Sacuvaj
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {show &&
        createPortal(<ModalContent onClose={handleClose} />, document.body)}
    </>
  );
};

export default AddEmployeeModal;
