import { useQuery } from "@tanstack/react-query";
import LocationMarker from "../AnyReactComponent/LocationMarker";
import Label from "../shared/Label/Label";
import GoogleMapReact from "google-map-react";
import ButtonPrimary from "../shared/Button/ButtonPrimary";
import Input from "../shared/Input/Input";
import Select from "../shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "../shared/FormItem/FormItem";
import { getInputs } from "../../api";

const PageAddLocation = ({
  city,
  setCity,
  street,
  setStreet,
  streetNumber,
  setStreetNumber,
  location,
  setLocation,
  saveCompany,
  setTabName,
}) => {
  const { data } = useQuery({
    queryKey: ["inputs"],
    queryFn: () => getInputs(),
    refetchOnMount: false,
  });

  const getLocationData = ({ latlang = null }) => {
    const geocoder = new window.google.maps.Geocoder();
    const address = `${street} ${streetNumber}, ${city}, Serbia`;
    const data = latlang ? { location: latlang } : { address: address };
    geocoder.geocode(
      {
        ...data,
      },
      function (results, status) {
        if (status === "OK") {
          if (latlang) {
            // setCity(results[0].address_components[3].long_name); TO DO: set city by id
            setStreet(results[0].address_components[1].long_name);
            setStreetNumber(results[0].address_components[0].long_name);
          } else {
            setLocation({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            });
          }
        } else {
          console.log(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      }
    );
  };

  const setLocationDataFromClick = (e) => {
    setLocation({ lat: e.lat, lng: e.lng });
    getLocationData({ latlang: { lat: e.lat, lng: e.lng } });
  };

  return (
    <CommonLayout
      index="02"
      handleClick={saveCompany}
      handleBack={() => setTabName("companyInfo")}
      nextBtnText="Sačuvaj"
    >
      <>
        <h2 className="text-2xl font-semibold">Lokacija firme</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem label="Grad">
            <Select onChange={(e) => setCity(e.target.value)} value={city}>
              {data?.cities.map((city) => (
                <option key={`location-${city.id}`} value={city.id}>
                  {city.name}
                </option>
              ))}
            </Select>
          </FormItem>
          <FormItem label="Ulica">
            <Input
              onChange={(e) => setStreet(e.target.value)}
              placeholder="..."
              value={street}
            />
          </FormItem>
          <FormItem label="Broj">
            <Input
              onChange={(e) => setStreetNumber(e.target.value)}
              value={streetNumber}
            />
          </FormItem>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
            <ButtonPrimary onClick={getLocationData}>
              Prikaži na mapi
            </ButtonPrimary>
          </div>
          <div>
            <Label>Detaljna adresa</Label>
            <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
              Unesite tačnu adresu firme ili selektujte lokaciju klikom na mapu.
            </span>
            <div className="mt-4">
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                <div className="rounded-xl overflow-hidden">
                  <GoogleMapReact
                    defaultZoom={15}
                    bootstrapURLKeys={{
                      key: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    center={{
                      lat: location.lat,
                      lng: location.lng,
                    }}
                    onClick={(e) => {
                      setLocationDataFromClick(e);
                    }}
                  >
                    <LocationMarker lat={location.lat} lng={location.lng} />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddLocation;
