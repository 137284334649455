import BgGlassmorphism from "../BgGlassmorphism/BgGlassmorphism";
import ListWithMap from "./ListWithMap";
import { Helmet } from "react-helmet";

const CompaniesList = ({ className = "" }) => {
  return (
    <div
      className={`nc-ListingStayMapPage relative ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>Terminko</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
        <ListWithMap />
      </div>
    </div>
  );
};

export default CompaniesList;
