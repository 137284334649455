import GallerySlider from "../../GallerySlider/GallerySlider";
import { Link } from "react-router-dom";
import BtnLikeIcon from "../../shared/BtnLikeIcon/BtnLikeIcon";
import Badge from "../../shared/Badge/Badge";
import { useAppState } from "../../../state/appState";

const StayCard = ({ size = "default", className = "", data }) => {
  const { user } = useAppState();
  const {
    galleryImgs = [
      "https://images.pexels.com/photos/1268871/pexels-photo-1268871.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    ],
    serviceCategory,
    street,
    name,
    services,
    streetNumber,
    manager,
    city,
    id,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={galleryImgs}
          href={`companies/${id}`}
        />
        <BtnLikeIcon
          companyId={id}
          userId={user.id}
          isLiked={false}
          className="absolute right-3 top-3 z-[1]"
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2 text-left">
          <span className="text-sm text-neutral-500 dark:text-neutral-400 ">
            {serviceCategory.name} · broj usluga {services.length}
          </span>
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{name}</span>
            </h2>
            <Badge name="VERIFIKOVAN" color="green" />
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{`${street} br ${streetNumber}, ${city.name}`}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">{manager.name}</div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={`companies/${id}`}>{renderContent()}</Link>
    </div>
  );
};

export default StayCard;
