import { useEffect } from "react";
import { Link } from "react-router-dom";
// import LogoSvg from "../../assets/svg/LogoSvg";
import logoPng from "../../assets/images/logo.png";
import MenuBar from "../shared/MenuBar/MenuBar";
import SwitchDarkMode from "../shared/SwitchDarkMode/SwitchDarkMode";
import ButtonPrimary from "../shared/Button/ButtonPrimary";
import DropdownServiceMenu from "./DropdownServiceMenu";
import LangDropdown from "./LangDropdown";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
// import CurrencyDropdown from "./CurrencyDropdown";
import MobileSearchMenu from "../MobileSearch/MobileSearchMenu";
import { useAppState } from "../../state/appState";
import { createPusherInstance } from "../utils/pusherInstance";

export default function MainNav({ className = "" }) {
  const { user } = useAppState();

  useEffect(() => {
    if (!user) return;
    const pusher = createPusherInstance();
    const channel = pusher.subscribe(`user.${user.id}`);
    channel.bind(
      "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      function (data) {
        alert("New Notification: " + data.message.message);
      }
    );
    return () => {
      pusher.unsubscribe(`user.${user.id}`);
    };
  }, [user]);

  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-2 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Link
            to="/"
            className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 w-36`}
          >
            <img src={logoPng} alt="hero" />
          </Link>
          <div className="hidden lg:block h-10 border-l border-neutral-300 dark:border-neutral-500"></div>
          <div className="hidden lg:block">{<DropdownServiceMenu />}</div>
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <img src={logoPng} alt="hero" className="mx-auto w-48" />
          <MobileSearchMenu />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden items-center lg:flex space-x-1">
            <LangDropdown />
            <Link
              to="/create-company"
              className="
                    text-opacity-90
                    group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              Dodaj svoj biznis
            </Link>

            <div></div>
            <SwitchDarkMode />
            <div className="pr-1.5">
              {user && <NotifyDropdown className="-ml-2 xl:-ml-1" />}
            </div>
            {user ? (
              <AvatarDropdown user={user} />
            ) : (
              <ButtonPrimary href="/login">Prijava</ButtonPrimary>
            )}
          </div>
          <div className="flex items-center space-x-2 lg:hidden">
            {user && <NotifyDropdown />}
            {user ? (
              <AvatarDropdown user={user} />
            ) : (
              <ButtonPrimary href="/login">Prijava</ButtonPrimary>
            )}
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
}
