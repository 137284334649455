import { useQuery } from "@tanstack/react-query";
import { useAppState } from "../../state/appState";
import CommonLayout from "./CommonLayout";
import { Tab } from "@headlessui/react";
import StayCard from "../shared/StayCard/StayCard";
import { getFavorites } from "../../api/";

const UserFavoriteList = () => {
  const { user } = useAppState();

  const { isPending, data, error } = useQuery({
    queryKey: ["userFavorites", user.id],
    queryFn: () => getFavorites({ userId: user.id }),
    disable: !user,
  });

  return (
    <CommonLayout>
      {" "}
      <div className="space-y-6 sm:space-y-8">
        {/* HEADING */}
        <h2 className="text-3xl font-semibold">
          {" "}
          {user.name} - Lista favorita
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Vaši omiljeni biznisi
        </span>
      </div>
      <div className="container mt-12 mb-24 lg:mb-32 flex flex-col  text-center">
        <div>
          <Tab.Group>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {data?.status.map((company) => (
                    <StayCard key={company.id} data={company} />
                  ))}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </CommonLayout>
  );
};

export default UserFavoriteList;
