import { useState } from "react";

const ServiceCard = ({ className = "", data, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-2 md:p-4 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        <div className=" space-y-1 ">
          <span className="text-xs text-neutral-500 font-normal">
            Opis usluge
          </span>
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
          <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base my-7 md:my-10 pt-1">
            {data.description}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="##" className="absolute inset-0" />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-24 lg:w-32 flex-shrink-0">
            {/* <img src={data.airlines.logo} className="w-10" alt="" /> */}
            <i
              className="lab la-whmcs text-2xl"
              style={{ color: "#0095da" }}
            ></i>
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span>{data.name}</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  Naziv usluge
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>{data.duration} min</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  Vreme trajanja
                </span>
              </div>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              Naziv usluge
            </div>
            <div className="font-medium text-lg ">{data.name}</div>
          </div>

          {/* TIMME */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className=" text-sm text-neutral-500 font-normal mt-0.5">
              {" "}
              Vreme trajanja
            </div>
            <div className="font-medium text-lg">{data.duration} min</div>
          </div>

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-brand-green  ">
                {data.price}
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              Cena usluge
            </div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default ServiceCard;
